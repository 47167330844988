.custom-view-body {
    background-color: #FFFFFF;
    font-family: Rubik;
    font-size: 14px;

}

.Toastify__progress-bar-theme--light {
    background: #CC0905;
    padding: 4px;
}

.toast-heading {
    color: #323348;
    font-family: Rubik;
    font-weight: 500;
    line-height: 15px;
    font-size: 15px;
    letter-spacing: -0.45%;
}

.toast-body {
    color: #5E6366;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.5%;
}

.Toastify__progress-bar {
    position: absolute;
    top: 0px !important;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: var(--toastify-z-index);
    opacity: 0.7;
    transform-origin: left;
}

.border-top-toast {
    border-top: 1px solid #CC0905;
    border-radius: 4px;
}