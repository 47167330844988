.primary-text{
  color: #222B88;
  font-weight: 400;
}

.primary-bold{
  color: #222B88;
  font-weight: 600;
}

.danger-text{
  color: #FD0000CC;
  font-weight: 400;
}